import React from 'react'
import { Page, getPage } from '../api/pageApi'
import NotFound from './NotFound'
import Tickets from './Tickets'
import { CustomTheme } from '../theme'
import Base from './Base'
import { CtxBase } from '@jaredpalmer/after'
import { RouteComponentProps } from 'react-router-dom'
import { ACCOUNT_NAMES, findByDomain } from '../data/accounts'
import ConfirmationPage from './ConfirmationPage/ConfirmationPage'

type AsyncProps = CtxBase & RouteComponentProps

interface DynamicPageProps extends AsyncProps {
  isIE: boolean
  page?: Page
  theme: CustomTheme
  isBrewAtZoo: boolean
  accountName: ACCOUNT_NAMES
}

class DynamicPage extends React.Component<DynamicPageProps> {
  static async getInitialProps({ isIE, match, theme, req }: DynamicPageProps) {
    const account = findByDomain(req?.hostname || window.location.hostname)

    const page = await getPage(account.id, match.url)
      .then((pageResponse) => pageResponse.data)
      .catch(() => undefined)

    if (typeof page === 'undefined') {
      return { statusCode: 404 }
    }
    const isBrewAtZoo = account.name === ACCOUNT_NAMES.BEEKSEBERGEN && match.url.startsWith('/brewatthezoo')

    return {
      isIE,
      page,
      theme,
      isBrewAtZoo,
      accountName: account.name,
    }
  }

  render = () => {
    const { page, theme, isIE, isBrewAtZoo, accountName } = this.props

    if (typeof page === 'undefined') {
      return <NotFound theme={theme} isIE={isIE} />
    }

    switch (page.type) {
      case 'rcx_exposition': {
        return (
          <Base theme={theme} isIE={isIE} isTicketShop={true} isBrewAtZoo={isBrewAtZoo}>
            <Tickets page={page} />
          </Base>
        )
      }
      case 'order_confirmation': {
        return (
          <Base theme={theme} isIE={isIE} accountName={accountName}>
            <ConfirmationPage accountName={accountName} page={page} />
          </Base>
        )
      }
      default: {
        return <NotFound theme={theme} isIE={isIE} />
      }
    }
  }
}

export default DynamicPage
